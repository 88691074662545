.syllabus-list-wrap {
  display: flex;
  flex-wrap: wrap;
}

.syllabus-list-item {
  width: 30%;
  margin-right: 3%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 25px;
  min-width: 200px;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
}

.syllabus-list-item h3 {
  margin: 0 0 10px;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.syllabus-icons {
  display: flex;
}

.syllabus-icons span {
  display: block;
  margin-right: 5px;
}

.syllabus-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.75);
  z-index: 9999;
  max-width: 100%;
  padding: 10% 5%;
  display: flex;
  align-items: center;
}
.syllabus-popup-content {
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0 0 10px #eee;
  background: #fff;
  position: relative;
}

.syllabus-popup-content h4 {
  font-size: 1.5rem;
  margin: 0 0 20px;
}

.syllabus-popup-form {
  display: flex;
  flex-direction: column;
}

input.syllabus-popup-input {
  width: 100%;
  margin: 0 0 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.syllabus-popup-btns {
  display: flex;
}

.syllabus-list-button {
  display: block;
  width: max-content;
  padding: 10px 20px;
  background: #444;
  color: #fff;
  border-radius: 5px;
  line-height: 1;
  cursor: pointer;
  margin-right: 5px;
  &:hover {
    background: #000;
  }
}
.syllabus-list-button + .syllabus-list-button {
  background: #fff;
  color: #444;
  &:hover {
    color: #000;
  }
}
.syllabus-popup-error {
  padding: 15px 0 0;
  color: red;
}

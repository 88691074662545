.home-content {
  width: 600px;
  max-width: 100%;
  padding: 3% 0;
  margin: 0 auto;
  h1 {
    margin: 0 0 10px;
    font-size: 32px;
  }
}

.home-btn-group {
  margin: 20px 0;
  input {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 16px;
    margin: 0 0 25px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
}

.home-content p {
  line-height: 1.5;
}

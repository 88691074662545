.seasons-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.season-item {
  width: 250px;
  height: 250px;
  background: #ffffff;
  box-shadow: 1px 3px 3px 3px rgba(173, 173, 173, 0.2);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30px 30px 0;
  position: relative;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background: #fafafa;
  }
}
.season-create-btn {
  font-size: 60px;
  line-height: 1;
  color: #888;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #444;
  }
}
.season-create-ip {
  position: absolute;
  top: 0;
  left: 0;
  background: #eee;
  width: 100%;
  height: 100%;
  padding: 20px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  input {
    padding: 10px 15px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    line-height: 1;
    margin: 0;
  }
  button {
    display: block;
    margin: 10px auto 0;
    background: #000;
    color: #fff;
    padding: 10px 20px;
    line-height: 1;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background: #333;
    }
  }
  .cancel-create {
    cursor: pointer;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 13px;
    opacity: 0.7;
    width: max-content;
    &:hover {
      text-decoration: underline;
    }
  }
}
.season-name {
  color: #000;
  font-size: 26px;
  font-weight: 600;
}

.season-day-list-row-title-container {
  width: 100%;
  height: 72px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.season-day-list-row-title-row {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-bottom: 2px solid #D1D1D1;
  justify-content: space-between;
}
.season-day-list-row-title-cal {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.season-day-list-row-title-group1596 {
  width: 40px;
  height: 40px;
  display: flex;
  align-self: flex-end;
  margin-top: auto;
  align-items: flex-start;
  flex-shrink: 1;
  margin-right: var(--dl-space-space-twounits);
  margin-bottom: auto;
  justify-content: center;
}
.season-day-list-row-title-text {
  color: rgb(41, 41, 41);
  height: auto;
  font-size: 16px;
  align-self: center;
  font-style: Regular;
  text-align: left;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.season-day-list-row-title-container1 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.season-day-list-row-title-text1 {
  color: rgb(41, 41, 41);
  width: auto;
  height: auto;
  font-size: 16px;
  align-self: center;
  font-style: Regular;
  margin-top: auto;
  text-align: left;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  margin-bottom: auto;
  text-decoration: none;
}
.season-day-list-row-title-container2 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: 0px;
  flex-direction: column;
}
.season-day-list-row-title-group1664 {
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 1;
  justify-content: space-between;
}
.season-day-list-row-title-text2 {
  color: rgb(41, 41, 41);
  height: auto;
  font-size: 16px;
  align-self: center;
  font-style: Regular;
  text-align: left;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  margin-left: var(--dl-space-space-oneandhalfunits);
  font-stretch: normal;
  margin-right: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.season-day-list-row-title-text3 {
  color: rgb(41, 41, 41);
  height: auto;
  font-size: 16px;
  align-self: center;
  font-style: Regular;
  text-align: left;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  margin-left: var(--dl-space-space-oneandhalfunits);
  font-stretch: normal;
  margin-right: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.season-day-list-row-title-text4 {
  color: rgb(41, 41, 41);
  height: auto;
  font-size: 16px;
  align-self: center;
  font-style: Regular;
  text-align: left;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  margin-left: var(--dl-space-space-oneandhalfunits);
  font-stretch: normal;
  margin-right: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.season-day-list-row-title-text5 {
  color: rgb(41, 41, 41);
  height: auto;
  font-size: 16px;
  align-self: center;
  font-style: Regular;
  text-align: left;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  margin-left: var(--dl-space-space-oneandhalfunits);
  font-stretch: normal;
  margin-right: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.season-day-list-row-title-container3 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  position: relative;
  align-self: center;
  margin-left: auto;
  flex-direction: column;
}
.season-day-list-row-title-text6 {
  color: rgb(41, 41, 41);
  height: auto;
  font-size: 16px;
  font-style: Regular;
  margin-top: auto;
  text-align: left;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  margin-bottom: auto;
  text-decoration: none;
}


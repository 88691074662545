.season-day-question-list-row-content-container {
  width: 100%;
  height: 72px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.season-day-question-list-row-content-row {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
}
.season-day-question-list-row-content-cal {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.season-day-question-list-row-content-group1596 {
  width: 40px;
  height: 40px;
  display: flex;
  align-self: flex-end;
  margin-top: auto;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: 10px;
  margin-bottom: auto;
  justify-content: center;
}
.season-day-question-list-row-content-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 2px;
  align-items: flex-start;
  flex-direction: column;
}
.season-day-question-list-row-content-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 11px;
  align-self: center;
  font-style: Regular;
  text-align: center;
  font-family: Geist;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.season-day-question-list-row-content-text2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: center;
  font-style: Medium;
  text-align: center;
  font-family: Geist;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.season-day-question-list-row-content-question-title {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: auto;
  flex-direction: column;
}
.season-day-question-list-row-content-text3 {
  margin-top: auto;
  margin-bottom: auto;
}
.season-day-question-list-row-content-container2 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.season-day-question-list-row-content-text4 {
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
}
.season-day-question-list-row-content-action {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  position: relative;
  align-self: center;
  margin-left: auto;
  flex-direction: row;
}
.season-day-question-list-row-content-ellipse9 {
  width: 36px;
  height: 36px;
  align-self: flex-start;
  margin-top: auto;
  margin-bottom: auto;
}



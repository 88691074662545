.syllabus-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.syllabus-home {
  top: 0px;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 883px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  justify-content: center;
}
.syllabus-frame-challengestitle {
  top: 98px;
  left: 0px;
  width: 222px;
  height: 32px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
  margin-left: var(--dl-space-space-fourunits);
}
.syllabus-text {
  color: rgb(0, 0, 0);
  height: auto;
  position: absolute;
  font-size: 26px;
  font-style: SemiBold;
  text-align: left;
  font-family: "Geist ";
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-frame1736 {
  gap: 49px;
  top: 160px;
  left: var(--dl-space-space-fourunits);
  right: var(--dl-space-space-fourunits);
  width: auto;
  margin: auto;
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  align-items: center;
}
.syllabus-leftcontainer {
  width: 655px;
  height: 601px;
  display: flex;
  align-items: flex-start;
  flex-shrink: 1;
  flex-direction: column;
}
.syllabus-group1723 {
  top: 0px;
  left: 72px;
  width: 583px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.syllabus-text01 {
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-text03 {
  left: 63px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-text05 {
  left: 120px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-text07 {
  left: 180px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-text09 {
  left: 240px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-text11 {
  left: 300px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-text13 {
  left: 360px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-text15 {
  left: 420px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-text17 {
  left: 480px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-text19 {
  left: 540px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-group1718 {
  top: 284px;
  left: 0px;
  width: 652px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.syllabus-text21 {
  top: 8px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-rectangle646 {
  top: 0px;
  left: 72px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle647 {
  top: 0px;
  left: 132px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle648 {
  top: 0px;
  left: 192px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle649 {
  top: 0px;
  left: 252px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle650 {
  top: 0px;
  left: 312px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle651 {
  top: 0px;
  left: 372px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle652 {
  top: 0px;
  left: 432px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle653 {
  top: 0px;
  left: 492px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle654 {
  top: 0px;
  left: 552px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle655 {
  top: 0px;
  left: 612px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-group1715 {
  top: 104px;
  left: 0px;
  width: 652px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.syllabus-text23 {
  top: 3px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-rectangle6461 {
  top: 0px;
  left: 72px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle6471 {
  top: 0px;
  left: 132px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle64801 {
  top: 0px;
  left: 192px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle6491 {
  top: 0px;
  left: 252px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
}
.syllabus-rectangle65001 {
  top: 0px;
  left: 312px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
}
.syllabus-rectangle6511 {
  top: 0px;
  left: 372px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
}
.syllabus-rectangle6521 {
  top: 0px;
  left: 432px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
}
.syllabus-rectangle6531 {
  top: 0px;
  left: 492px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
}
.syllabus-rectangle6541 {
  top: 0px;
  left: 552px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle6551 {
  top: 0px;
  left: 612px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-group1719 {
  top: 344px;
  left: 0px;
  width: 652px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.syllabus-text25 {
  top: 8px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-rectangle6462 {
  top: 0px;
  left: 72px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6472 {
  top: 0px;
  left: 132px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle64802 {
  top: 0px;
  left: 192px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6492 {
  top: 0px;
  left: 252px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle65002 {
  top: 0px;
  left: 312px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6512 {
  top: 0px;
  left: 372px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6522 {
  top: 0px;
  left: 432px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6532 {
  top: 0px;
  left: 492px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6542 {
  top: 0px;
  left: 552px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6552 {
  top: 0px;
  left: 612px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-group1716 {
  top: 164px;
  left: 0px;
  width: 652px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.syllabus-text27 {
  top: 3px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-rectangle6463 {
  top: 0px;
  left: 72px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle6473 {
  top: 0px;
  left: 132px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle64803 {
  top: 0px;
  left: 192px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle6493 {
  top: 0px;
  left: 252px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
}
.syllabus-rectangle65003 {
  top: 0px;
  left: 312px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle6513 {
  top: 0px;
  left: 372px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
}
.syllabus-rectangle6523 {
  top: 0px;
  left: 432px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
}
.syllabus-rectangle6533 {
  top: 0px;
  left: 492px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle6543 {
  top: 0px;
  left: 552px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle6553 {
  top: 0px;
  left: 612px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-group1720 {
  top: 404px;
  left: 0px;
  width: 652px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.syllabus-text29 {
  top: 8px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-rectangle6464 {
  top: 0px;
  left: 72px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6474 {
  top: 0px;
  left: 132px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle64804 {
  top: 0px;
  left: 192px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6494 {
  top: 0px;
  left: 252px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle65004 {
  top: 0px;
  left: 312px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6514 {
  top: 0px;
  left: 372px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6524 {
  top: 0px;
  left: 432px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6534 {
  top: 0px;
  left: 492px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6544 {
  top: 0px;
  left: 552px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6554 {
  top: 0px;
  left: 612px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-group1717 {
  top: 224px;
  left: 0px;
  width: 652px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.syllabus-text31 {
  top: 8px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-rectangle6465 {
  top: 0px;
  left: 72px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-color: var(--dl-color-primary-500);
  border-width: 3px;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6475 {
  top: 0px;
  left: 132px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle64805 {
  top: 0px;
  left: 192px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6495 {
  top: 0px;
  left: 252px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle65005 {
  top: 0px;
  left: 312px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6515 {
  top: 0px;
  left: 372px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6525 {
  top: 0px;
  left: 432px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6535 {
  top: 0px;
  left: 492px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6545 {
  top: 0px;
  left: 552px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6555 {
  top: 0px;
  left: 612px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-group1721 {
  top: 464px;
  left: 0px;
  width: 652px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.syllabus-text33 {
  top: 8px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-rectangle6466 {
  top: 0px;
  left: 72px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6476 {
  top: 0px;
  left: 132px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle64806 {
  top: 0px;
  left: 192px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6496 {
  top: 0px;
  left: 252px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle65006 {
  top: 0px;
  left: 312px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6516 {
  top: 0px;
  left: 372px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6526 {
  top: 0px;
  left: 432px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6536 {
  top: 0px;
  left: 492px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6546 {
  top: 0px;
  left: 552px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6556 {
  top: 0px;
  left: 612px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-group1722 {
  top: 524px;
  left: 0px;
  width: 652px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.syllabus-text35 {
  top: 8px;
  color: rgba(132, 132, 132, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: "Geist ";
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-rectangle6467 {
  top: 0px;
  left: 72px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6477 {
  top: 0px;
  left: 132px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle64807 {
  top: 0px;
  left: 192px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6497 {
  top: 0px;
  left: 252px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle65007 {
  top: 0px;
  left: 312px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6517 {
  top: 0px;
  left: 372px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6527 {
  top: 0px;
  left: 432px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6537 {
  top: 0px;
  left: 492px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6547 {
  top: 0px;
  left: 552px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-rectangle6557 {
  top: 0px;
  left: 612px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-color: #D9D9D9;
}
.syllabus-group1714 {
  top: 44px;
  left: 3px;
  width: 649px;
  height: 40px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.syllabus-rectangle6478 {
  top: 0px;
  left: 129px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle64808 {
  top: 0px;
  left: 76px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle64809 {
  top: 0px;
  left: 189px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle6498 {
  top: 0px;
  left: 249px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
}
.syllabus-rectangle65008 {
  top: -2px;
  left: 368px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(45deg, rgb(192, 88, 36) 0.00%,rgb(240, 203, 53) 100.00%);
}
.syllabus-rectangle65009 {
  top: 0px;
  left: 309px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(45deg, rgb(192, 88, 36) 0.00%,rgb(240, 203, 53) 100.00%);
}
.syllabus-rectangle6528 {
  top: 0px;
  left: 429px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(45deg, rgb(192, 88, 36) 0.00%,rgb(240, 203, 53) 100.00%);
}
.syllabus-rectangle6538 {
  top: 0px;
  left: 489px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-rectangle6548 {
  top: 0px;
  left: 549px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(254, 172, 94) 0.00%,rgb(199, 121, 208) 50.00%,rgb(75, 192, 200) 100.00%);
}
.syllabus-rectangle6558 {
  top: 0px;
  left: 609px;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  border-radius: 10px;
  background-image: linear-gradient(315deg, rgb(194, 229, 156) 0.00%,rgb(100, 179, 244) 100.00%);
}
.syllabus-text37 {
  top: 8px;
  color: rgba(41, 41, 41, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-text39 {
  top: 8px;
  left: 36px;
  color: rgba(41, 41, 41, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-group1724 {
  top: 577px;
  left: 613px;
  width: 39px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.syllabus-text41 {
  color: rgba(41, 41, 41, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-text43 {
  left: 25px;
  color: rgba(41, 41, 41, 1);
  height: auto;
  position: absolute;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.syllabus-image {
  width: 456px;
  height: 604px;
  object-fit: cover;
  margin-left: auto;
  margin-right: var(--dl-space-space-threeunits);
  border-radius: 35px;
}

.season-day-question-list-container {
  width: 100%;
  display: flex;
  overflow: auto;
  position: relative;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.season-day-question-list-home {
  gap: var(--dl-space-space-unit);
  top: 60px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 60px;
  height: auto;
  display: flex;
  position: absolute;
  overflow-y: scroll;
  flex-shrink: 0;
  border-radius: 10px;
  flex-direction: column;
}
.season-day-question-list-container1 {
  width: 100%;
  height: 32px;
  display: flex;
  position: relative;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-bottom: 0px;
}
.season-day-question-list-frame-challengestitle {
  width: 222px;
  height: 32px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 1;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: auto;
}
.season-day-question-list-text {
  color: rgb(0, 0, 0);
  height: auto;
  position: absolute;
  font-size: 26px;
  font-style: SemiBold;
  text-align: left;
  font-family: Geist;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.season-day-question-list-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}

.season-day-list-row-content-container {
  width: 100%;
  height: 72px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.season-day-list-row-content-row {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
}
.season-day-list-row-content-cal {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.season-day-list-row-content-group1596 {
  width: 40px;
  height: 40px;
  display: flex;
  align-self: flex-end;
  margin-top: auto;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: 10px;
  margin-bottom: auto;
  justify-content: center;
}
.season-day-list-row-content-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 2px;
  align-items: flex-start;
  flex-direction: column;
}
.season-day-list-row-content-text {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 11px;
  align-self: center;
  font-style: Regular;
  text-align: center;
  font-family: Geist;
  font-weight: 400;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.season-day-list-row-content-text2 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 18px;
  align-self: center;
  font-style: Medium;
  text-align: center;
  font-family: Geist;
  font-weight: 500;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.season-day-list-row-content-container2 {
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
  flex-direction: column;
}
.season-day-list-row-content-text3 {
  color: rgb(41, 41, 41);
  width: 67px;
  height: auto;
  font-size: 16px;
  align-self: center;
  font-style: Regular;
  margin-top: auto;
  text-align: center;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  margin-bottom: auto;
  text-decoration: none;
}
.season-day-list-row-content-container3 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  margin-right: 0px;
  flex-direction: column;
}
.season-day-list-row-content-group1664 {
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 1;
  justify-content: space-between;
}
.season-day-list-row-content-text4 {
  color: rgb(41, 41, 41);
  width: 45px;
  height: auto;
  font-size: 16px;
  align-self: center;
  font-style: Regular;
  text-align: center;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  margin-left: 222px;
  font-stretch: normal;
  text-decoration: none;
}
.season-day-list-row-content-text5 {
  color: rgb(41, 41, 41);
  width: 63px;
  height: auto;
  font-size: 16px;
  align-self: center;
  font-style: Regular;
  text-align: center;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  margin-left: var(--dl-space-space-oneandhalfunits);
  font-stretch: normal;
  margin-right: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.season-day-list-row-content-text6 {
  color: rgb(41, 41, 41);
  width: 40px;
  height: auto;
  font-size: 16px;
  align-self: center;
  font-style: Regular;
  text-align: center;
  font-family: Geist;
  font-weight: 400;
  line-height: 24px;
  margin-left: var(--dl-space-space-oneandhalfunits);
  font-stretch: normal;
  margin-right: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}
.season-day-list-row-content-action {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  position: relative;
  align-self: center;
  margin-left: auto;
  flex-direction: row;
}
.season-day-list-row-content-create-new {
  color: var(--dl-color-gray-white);
  padding: var(--dl-space-space-halfunit);
  margin-top: auto;
  padding-top: 0px;
  margin-right: var(--dl-space-space-threeunits);
  padding-left: 0px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: auto;
  padding-right: 0px;
  padding-bottom: 0px;
  background-color: var(--dl-color-gray-black);
}
.season-day-list-row-content-ellipse9 {
  width: 36px;
  height: 36px;
  align-self: flex-start;
  margin-top: auto;
  margin-bottom: auto;
}



.navbar-container {
  width: 100%;
  height: 60px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 1px solid #ececec;
  background-color: #ffffff;
  font-size: 14px;
  .container {
    height: 100%;
  }
}

.navbar-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
}

.navbar-right-elements {
  display: flex;
  align-items: center;
}

.navbar-navlink {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  line-height: 1;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: #333;
  }
  &.navbar-btn {
    color: #524f44;
    font-size: 16px;
    background: #e3e0d6;
    border-radius: 39px;
    min-height: 36px;
    min-width: 85px;
    padding: 0 20px;
    margin-right: 15px;
    &.navbar-btn-invert {
      color: #fff;
      background: #000;
    }
    &:hover {
      opacity: 0.8;
    }
    @media (max-width: 991px) {
      font-size: 14px;
    }
  }
}

.navbar-center {
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    display: none;
  }
  a {
    display: block;
    position: relative;
    margin-right: 30px;
    &.current {
      color: #000;
    }
    &::after {
      content: "";
      width: 10px;
      height: 10px;
      background: url(../external/breadcrumb-icon.svg) no-repeat center;
      display: block;
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.5;
      pointer-events: none;
    }
    &:last-child::after {
      display: none;
    }
  }
}

.user-button {
  cursor: pointer;
  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: block;
    object-fit: cover;
  }
}

ul.user-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  max-width: 240px;
  min-width: 120px;
  background: #fff;
  list-style: none;
  margin: 0;
  padding: 15px 0;
  border: 0;
  box-shadow: 0px 2px 5px #ccc;
  border-radius: 0 0 5px 5px;
  z-index: 2;
  li {
    padding: 5px 15px;
    a {
      color: #666;
      &:hover {
        color: #000;
      }
    }
  }
}

@media (max-width: 600px) {
  .navbar-right-elements {
    .navbar-navlink:not(.navbar-btn) {
      display: none;
    }
  }
}

.title-btn {
  margin: 15px 0 0;
  @media (min-width: 768px) {
    position: absolute;
    right: 0;
    top: 30px;
    margin: 0;
  }
}

.title-btn button.btn_primary {
  width: 150px;
  padding: 15px;
}
.season-day-list-data {
  overflow: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    border: 0;
  }

  th {
    color: #848484;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #d1d1d1;
    padding: 10px;
  }

  th:first-child {
    padding-left: 0;
    text-align: left;
  }
  th:last-child {
    text-align: right;
    padding-right: 0;
  }

  td {
    color: #292929;
    text-align: center;
    border-bottom: 1px solid #d1d1d1;
    padding: 20px 10px;
    .challenges-date {
      margin: 0;
    }
  }
  td:first-child {
    padding-left: 0;
    border-bottom: 0;
    text-align: left;
  }
  td:last-child {
    text-align: right;
    padding-right: 0;
  }
  .table-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      font-size: 14px;
      color: #000;
      margin-right: 20px;
      cursor: pointer;
      padding: 5px;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }
    .btn-icon {
      width: 36px;
      min-width: 36px;
      height: 36px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f0f0f0;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        background: #e2e2e2;
      }
      svg,
      img {
        display: block;
      }
    }
  }
}

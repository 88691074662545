/* GridComponent.css */

.grid-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 80px;
}

.grid-title {
  font-size: 24px;
  margin: 0 0 20px;
  font-weight: 700;
  padding: 15px 0;
}

.grid-row {
  display: flex;
  align-items: center;
}

.grid-square {
  width: 40px;
  height: 40px;
  margin: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #d9d9d9;
  cursor: pointer;
}

.row-heading {
  text-align: left;
  padding: 0;
  width: 75px;
  color: #848484;
  font-size: 14px;
}

.col-heading {
  text-align: center;
}

.grid-col {
  display: flex;
  align-items: center;
  color: #848484;
  margin: 0 0 10px;
  font-size: 14px;
}
.grid-bottom {
  color: #848484;
  font-size: 12px;
  text-align: right;
  padding: 10px;
}

/* Add this CSS to your existing styles or create a new CSS file */

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal-content {
  background-color: #fff;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.bottom-button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 32px;
}

.bottom-button {
  flex: 1;
  padding: 8px;
  margin: 4px;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: pointer;
}

.bottom-button:hover {
  background-color: #d9d9d9;
}

label {
  display: block;
  margin-bottom: 10px;
}

input {
  margin-right: 5px;
}

.grid-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  .grid-left {
    overflow: auto;
    width: 100%;
    @media (min-width: 768px) {
      width: 55%;
    }
  }
  .grid-cols-wrap {
    width: fit-content;
  }
  .grid-right {
    width: 100%;
    margin-top: 30px;
    @media (min-width: 768px) {
      margin-top: 0;
      width: 45%;
      padding-left: 20px;
    }
    img {
      display: block;
      border-radius: 20px;
    }
  }
}
img {
  max-width: 100%;
}

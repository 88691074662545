.challenges-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 0;
  flex-direction: column-reverse;
  @media (min-width: 991px) {
    padding: 30px 0;
    flex-direction: row;
  }
}

.challenges-date {
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ececec;
  border-radius: 10px;
  line-height: 1;
  margin-bottom: 25px;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  color: #000000;

  span.day-text {
    display: block;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1;
    position: relative;
    margin: 0 0 2px;
  }

  span.day-number {
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-top: 1px;
  }
}

.challenges-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  .challenges-left-details {
    flex-grow: 1;
  }
  @media (min-width: 991px) {
    width: 45%;
    padding-right: 3%;
  }
}

.challenges-right {
  width: 100%;
  background: #e5ecf3;
  border-radius: 10px;
  padding: 15px;
  margin: 0 0 30px;
  @media (min-width: 991px) {
    border-radius: 30px;
    padding: 30px;
    width: 55%;
    margin: 0;
  }
}

.left-detail-item {
  margin: 0 0 20px;
  color: #292929;
  h3 {
    margin: 0 0 10px;
    font-size: 18px;
    font-weight: 600;
  }
  p,
  li {
    margin: 0 0 15px;
  }

  ol,
  ul {
    margin: 0;
    padding: 0 0 0 30px;
  }
  input,
  textarea {
    display: block;
    width: 100%;
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
  }
  textarea {
    resize: vertical;
    min-height: 80px;
    max-height: 300px;
  }
}

.challenges-left-bottom {
  margin: 30px 0 0;
}

.challenges-right-top {
  display: flex;
  margin: 0 0 15px;
  flex-direction: column;
  align-items: center;
  @media (min-width: 541px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.design-logo-title {
  display: flex;
  align-items: center;
  img {
    display: block;
    border-radius: 10px;
    width: 47px;
    height: 47px;
    object-fit: cover;
  }
  .design-title {
    margin-left: 10px;
    input {
      background: #c5d0dc;
      font-size: 13px;
      display: block;
      padding: 3px 6px;
      border-radius: 5px;
      line-height: 1;
    }
  }
}

.refer-more-screens {
  margin: 15px 0 0;
  display: none;
  @media (min-width: 541px) {
    display: block;
  }
  button {
    background: #000;
    color: #fff;
    padding: 15px 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
    svg {
      display: block;
      margin-left: 5px;
    }
    &:hover {
      background: #444;
    }

    span {
      display: block;
    }
  }
}

.challenges-right-screens {
  display: flex;
  flex-wrap: wrap;
}

.right-screen-design {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 541px) {
    width: 45%;
  }
  @media (min-width: 768px) {
    align-items: flex-start;
  }
}

.right-divider {
  width: 100%;
  padding: 10px;
  display: flex;
  svg {
    display: block;
    width: 100%;
    height: auto;
    max-width: 40px;
    margin: 0 auto;
    transform: rotate(90deg);
  }
  @media (min-width: 541px) {
    width: 10%;
    padding: 30px 10px;
    svg {
      transform: none;
      max-width: 100%;
    }
  }
}

.right-screen-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 541px) {
    width: 45%;
  }
}

.upload-screen {
  display: block;
  border-radius: 20px;
  width: 100%;
  height: auto;
  background: #c5d0dc;
  padding: 0;
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  &:hover {
    background: #bac6d3;
  }
}

.right-info-wrap {
  width: 100%;
  height: auto;
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.right-info-wrap-container {
  z-index: 2;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .challenge-survey-button {
    width: 280px;
    height: 60px;
    margin: 8px auto;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ddd;
    border-radius: 60px;
    color: #000;
    max-width: 100%;
    position: relative;
    cursor: pointer;
    &:hover {
      opacity: 0.85;
    }
    .sb-icon {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
    }
  }
}

.right-info-wrap h3 {
  margin: 0;
  font-size: 18px;
}

.right-detail-item {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  padding: 30px 0;
  position: relative;
  &::after {
    content: "";
    display: block;
    width: 100%;
    max-width: 130px;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    border: 1px dotted #e4dabb;
  }
  &:last-child::after {
    display: none;
  }
}

.right-detail-item h4 {
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 699;
  color: #292929;
}
